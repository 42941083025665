<template>
  <v-card class="rounded-xl info_card">
    <v-img
      class="surface"
      height="240"
      :aspect-ratio="1 / 1"
      :src="require(`@/assets/images/${content.imageURL}`)"
    >
      <!-- <template v-slot:placeholder>
      :src="require('@/assets/images/HomePage/Topmain.png')"
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template> -->
    </v-img>

    <v-card-title
      class="headline primary--text font-weight-bold justify-center"
    >
      {{ content.title }}
    </v-card-title>
    <v-card-text>
      <div v-for="feature in content.features" :key="feature">
        - {{ feature }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    content: Object,
  },
};
</script>

<style></style>
