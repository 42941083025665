<template>
  <v-container fluid class="pa-0">
    <landing-section class="" />
    <service-section
      style="padding-top: 96px !important; padding-bottom: 96px !important"
    />
    <product-section
      style="padding-top: 96px !important; padding-bottom: 96px !important"
    />
    <shop-section
      style="padding-top: 144px !important; padding-bottom: 144px !important"
    />
    <education-section />
    <page-footer />
  </v-container>
</template>

<script>
import PageFooter from "@/components/Footer/PageFooter.vue";
import LandingSection from "./Components/LandingSection.vue";
import ServiceSection from "./Components/ServiceSection.vue";
import ProductSection from "./Components/ProductSection.vue";
import ShopSection from "./Components/ShopSection.vue";

import setMeta from "@/utils/setMeta";
import EducationSection from "./Components/EducationSection.vue";
export default {
  components: {
    PageFooter,
    LandingSection,
    ServiceSection,
    ProductSection,
    ShopSection,
    EducationSection,
  },
  name: "Home",
  beforeCreate() {
    setMeta({
      title: "홈",
      description: "메이커 문화의 중심 메이드올입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Fmain.png?alt=media&token=d87cafdc-d36d-428c-bc9d-44d66386d74b",
    });
  },
};
</script>
