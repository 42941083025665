<template>
  <v-container fluid class="background pa-0">
    <v-img
      height="720"
      :src="require('@/assets/images/HomePage/Education/Background.png')"
      style="
        align-items: center;
        padding-top: 144px !important;
        padding-bottom: 144px !important;
      "
    >
      <v-row style="max-width: 1080px" class="mx-auto">
        <v-col cols="12">
          <v-card class="transparent" dark flat>
            <v-card-title
              class="font-weight-bold headline text-center justify-center"
            >
              <span
                >코딩부터 3D프린팅까지 프로젝트 기반
                <span class="secondary--text word-break">메이커 교육</span>
              </span>
            </v-card-title>
            <v-card-text class="body-1 text-center">
              하드웨어와 소프트웨어 융합 교육 컨텐츠들로 학생들의 흥미유발과
              창의력 증진을 확인해보세요!
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <education-carousel :contents="contents" />
        </v-col>
      </v-row>
    </v-img>
  </v-container>
</template>

<script>
import EducationCarousel from "@/components/Carousel/EducationCarousel.vue";
export default {
  components: { EducationCarousel },
  data() {
    return {
      contents: [
        {
          title: "MA3D: Mini 3D 프린터",
          subtitle: "프로그램 설치 및 프린터 조작법",
          imageURL: "HomePage/Education/MA3D-Mini.png",
          link: "",
        },
        {
          title: "MA3D: Standard 3D 프린터",
          subtitle: "프로그램 설치 및 프린터 조작법",
          imageURL: "HomePage/Education/MA3D-Standard.png",
          link: "",
        },
        {
          title: "Tinkercad 3D 모델링",
          subtitle: "프로그램 설치 및 기초 사용법",
          imageURL: "HomePage/Education/Tinkercad.png",
          link: "",
        },
        {
          title: "Fusion360 3D 모델링",
          subtitle: "프로그램 설치 및 UI 소개",
          imageURL: "HomePage/Education/Fusion360.png",
          link: "",
        },
        {
          title: "Arduino 아두이노 코딩",
          subtitle: "아두이노 설치 및 기초 교육 자료",
          imageURL: "HomePage/Education/Arduino.png",
          link: "",
        },
      ],
    };
  },
};
</script>

<style></style>
