<template>
  <v-container style="" fluid class="gradient-background">
    <v-row style="max-width: 840px" class="mx-auto">
      <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : ''">
        <v-card class="transparent" dark flat>
          <v-card-title
            class="font-weight-bold text-center justify-center"
            :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'title'"
          >
            메이드올의 주문 시스템을 이용하는<br />
            전국 각지의 메이커스페이스를 만나보세요.
          </v-card-title>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="8"
        :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : ''"
      >
        <v-card class="transparent" flat dark>
          <v-card-title class="body-1 text-center">
            온라인 주문과 꼼꼼한 고객 상담, 체계화된 주문 관리 시스템으로
            메이커스페이스 운용률을 획기적으로 높일 수 있습니다.
          </v-card-title>
          <v-card-text class="text-center">
            <v-btn
              v-for="item in workSpace"
              :key="item.title"
              max-width="30%"
              height="45"
              color="white"
              class="px-0"
              :class="$vuetify.breakpoint.smAndDown ? 'ma-1' : 'ma-2'"
              style="cursor: unset"
              depressed
              large
              :ripple="false"
            >
              <v-img
                contain
                :class="$vuetify.breakpoint.smAndDown ? 'ml-2' : 'ml-3'"
                :width="$vuetify.breakpoint.smAndDown ? '28' : '30'"
                height="34"
                :src="
                  require(`@/assets/images/HomePage/Service/${item.iconImage}`)
                "
              />
              <v-img
                contain
                :class="$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mr-3'"
                :width="$vuetify.breakpoint.smAndDown ? '66' : '96'"
                height="34"
                :src="
                  require(`@/assets/images/HomePage/Service/${item.typoImage}`)
                "
              />
            </v-btn>
            <v-btn
              min-width="70%"
              color="white"
              class="mt-8 font-weight-bold body-1"
              outlined
              @click="routerPush('Service')"
              large
            >
              <v-icon>mdi-plus</v-icon>
              서비스 더 알아보기
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="4">
        <v-card class="transparent" flat>
          <v-img
            contain
            :max-height="420"
            :style="
              $vuetify.theme.dark ? 'filter: brightness(0) invert(1);' : ''
            "
            :src="require('@/assets/images/HomePage/Service/map.png')"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    routerPush(routerName, index) {
      if (routerName === "") {
        alert("페이지 준비중입니다.");
        this.selectedItem = index;
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
  data() {
    return {
      workSpace: [
        {
          iconImage: "KHU_icon.png",
          typoImage: "KHU_typo.png",
        },
        {
          iconImage: "Madeall_icon.png",
          typoImage: "Madeall_typo.png",
        },
        {
          iconImage: "TheMJ_icon.png",
          typoImage: "TheMJ_typo.png",
        },
      ],
    };
  },
};
</script>

<style></style>
