<template>
  <v-container fluid class="surface">
    <v-row style="max-width: 1080px" class="mx-auto">
      <v-col cols="12">
        <v-card class="transparent" flat>
          <v-card-title
            class="font-weight-bold display-1 text-center justify-center"
          >
            <span
              >메이커 활동에 필요한 물품들만 콕 집어 판매하는
              <span class="primary--text">쇼핑몰</span>
            </span>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn
              class="my-2 font-weight-bold body-1"
              color="primary"
              large
              depressed
              :href="'https://smartstore.naver.com/madeall'"
              target="_blank"
            >
              <v-icon>mdi-plus</v-icon>
              쇼핑몰 바로가기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        v-for="(item, i) in contents"
        :key="item.title + i"
        cols="12"
        sm="6"
        md="3"
      >
        <div class="">
          <shop-card :content="item" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopCard from "@/components/Card/ShopCard.vue";
export default {
  components: { ShopCard },
  data() {
    return {
      contents: [
        {
          title: "아두이노",
          imageURL: "HomePage/Mall/Arduino.png",
          link: "https://smartstore.naver.com/madeall/category/bf2ed3d4ba8a40bb9c487025f0480cf0?cp=1",
        },
        {
          title: "3D 프린터",
          imageURL: "HomePage/Mall/3DPrinter.png",
          link: "https://smartstore.naver.com/madeall/category/c6f14e8d8e7a43caa3dc3e542901bd9b?cp=1",
        },
        {
          title: "라즈베리파이",
          imageURL: "HomePage/Mall/RaspberryPi.png",
          link: "https://smartstore.naver.com/madeall/category/4402eff717654ebc961fb912451b9305?cp=2",
        },
        {
          title: "기계부품",
          imageURL: "HomePage/Mall/Components.png",
          link: "https://smartstore.naver.com/madeall/category/85d9ac792da84b2f94a20e9d94013ea9?cp=1",
        },
      ],
    };
  },
};
</script>

<style></style>
