<template>
  <v-container fluid class="background">
    <v-row style="max-width: 1080px" class="mx-auto">
      <v-col cols="12">
        <v-card class="transparent" flat>
          <v-card-title
            class="font-weight-bold display-1 text-center justify-center"
          >
            <span>
              언제나 균일한 품질을 제공하는 메이드올
              <span class="primary--text word-break">제품 라인업</span>
            </span>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn
              class="my-2 font-weight-bold body-1"
              color="primary"
              outlined
              large
              @click="routerPush('Products')"
              target="_blank"
            >
              <v-icon>mdi-plus</v-icon>
              자세히 알아보기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12">
        <product-carousel :products="products" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductCarousel from "@/components/Carousel/ProductCarousel.vue";
export default {
  components: { ProductCarousel },
  data() {
    return {
      products: [
        {
          title: "MA3D:Mini",
          imageURL: "HomePage/Product/MA3D-Mini.png",
          features: {
            Volume: "120*120*150",
            Feature: "Dual Nozzle",
            Purpose: "For Education",
          },
          link: "",
        },
        {
          title: "MA3D:Standard",
          imageURL: "HomePage/Product/MA3D-Standard.png",
          features: {
            Volume: "310*310*350",
            Feature: "Volcano Nozzle",
            Purpose: "For Fast Production",
          },
          link: "",
        },
        {
          title: "MA3D:Filament",
          imageURL: "HomePage/Product/MA3D-Pro.png",
          features: {
            Volume: "PLA, PETG",
            Feature: "1kg , 20kg/Box",
            Purpose: "다양한 컬러 / 국내 생산",
          },
          link: "",
        },
      ],
    };
  },
  methods: {
    routerPush(routerName, index) {
      if (routerName === "") {
        alert("서비스 준비중입니다.");
        this.selectedItem = index;
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
};
</script>

<style></style>
