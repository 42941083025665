<template>
  <v-card
    class="rounded-xl info_card"
    :href="content.link"
    target="_blank"
    :ripple="false"
  >
    <v-img
      class="surface"
      min-height="120"
      :aspect-ratio="2 / 1"
      :src="require(`@/assets/images/${content.imageURL}`)"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <!-- <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular> -->
        </v-row>
      </template>
    </v-img>

    <v-card-title class="headline font-weight-bold justify-center pb-0">
      {{ content.title }}
    </v-card-title>
    <v-card-actions class="justify-center">
      <v-btn color="primary" icon><v-icon>mdi-arrow-right</v-icon></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    content: Object,
  },
};
</script>

<style></style>
