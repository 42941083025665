<template>
  <div>
    <v-img
      style="align-items: center"
      max-height="640"
      :src="
        $vuetify.breakpoint.mdAndUp
          ? require('@/assets/images/HomePage/TopMain.png')
          : require('@/assets/images/HomePage/TopMain_Mobile.png')
      "
    >
      <v-row style="max-width: 1080px" class="mx-auto my-12">
        <v-col cols="12">
          <v-card class="transparent text-center" style="" dark flat>
            <v-card-title style="display: contents !important">
              <div
                class="font-weight-bold mb-1"
                :class="
                  $vuetify.breakpoint.smAndDown ? 'headline' : 'display-1'
                "
              >
                메이커 문화가 시작되는 곳,
              </div>
              <div
                class="font-weight-bold"
                :class="
                  $vuetify.breakpoint.smAndDown ? 'headline' : 'display-2'
                "
                style="
                  background: linear-gradient(90deg, #36d1dc 0%, #5b86e5 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                "
              >
                온라인 메이커스페이스 서비스
              </div>
            </v-card-title>

            <v-card-actions class="mt-5 justify-center">
              <v-btn
                v-if="$vuetify.breakpoint.smAndDown"
                color="primary"
                class="font-weight-regular px-4"
                @click="routerPush('Order')"
                depressed
              >
                주문제작 접수하기
              </v-btn>
              <v-btn
                v-else
                color="primary"
                class="font-weight-bold px-4"
                x-large
                @click="routerPush('Order')"
                depressed
              >
                주문제작 접수하기
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <wave />
    </v-img>
    <v-row style="max-width: 1080px; margin: 64px" class="mx-auto">
      <v-col
        align-self="center"
        v-for="item in infos"
        :key="item.title"
        cols="6"
        md="3"
      >
        <v-card class="transparent text-center" flat>
          <v-card-subtitle class="pa-0 subtitle-1">
            <v-icon small color="primary">{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-card-subtitle>
          <v-card-title
            class="font-weight-bold justify-center"
            :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'"
          >
            <Roller :text="item.content" :transition="2" :wordWrap="0" />
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Roller from "vue-roller";
import Wave from "@/components/Assets/Wave.vue";
export default {
  components: { Roller, Wave },
  methods: {
    noServiceAlert() {
      alert("서비스 준비중입니다.");
    },
    routerPush(routerName, index) {
      if (routerName === "") {
        alert("서비스 준비중입니다.");
        this.selectedItem = index;
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
  data() {
    return {
      infos: [
        {
          title: "Makerspace",
          icon: "mdi-map-marker",
          content: "전국 3개",
        },
        {
          title: "3D Printers",
          icon: "mdi-printer-3d",
          content: "32대",
        },
        {
          title: "Makers",
          icon: "mdi-account-group",
          content: "5072명",
        },
        {
          title: "3D Print Cost ",
          icon: "mdi-cash-multiple",
          content: "3.5억원",
        },
      ],
    };
  },
};
</script>

<style></style>
